import React, { useState, useEffect, BaseSyntheticEvent } from 'react'
import AliceCarousel from 'react-alice-carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

// Hooks
import useWindowSize from '../../../hooks/windowSize'

// Model
import { ComicModel, GenreModel } from '../../../models'

// Components
import ComicCard from '../../../components/atoms/ComicCard'

// Style
import {
  next,
  prev,
  navButton,
  homeCarrouselWrapper,
} from './style.module.scss'

interface Props {
  comics: ComicModel[]
  genre: GenreModel
}

const Carrousel: React.FC<Props> = ({ comics, genre }) => {
  const [jump, setJump] = useState(2)
  const { width } = useWindowSize()

  useEffect(() => {
    if (width > 1439) {
      setJump(5)
    } else if (width > 1023) {
      setJump(3)
    } else if (width > 399) {
      setJump(2)
    } else {
      setJump(1)
    }
  }, [width])

  const responsive = {
    0: { items: 2 },
    399: { items: 3 },
    1023: { items: 4 },
    1439: { items: 6 },
  }

  const renderSlides = () =>
    comics.map((c) => (
      <div key={`${genre.name}_${c.name}`}>
        <ComicCard comic={c} exclude={genre.id} />
      </div>
    ))

  return (
    <div className={homeCarrouselWrapper}>
      <AliceCarousel
        items={renderSlides()}
        responsive={responsive}
        infinite
        mouseTracking
        animationType={'slide'}
        
        renderPrevButton={() => (
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={[navButton, prev].join(' ')}
          />
        )}
        renderNextButton={() => (
          <FontAwesomeIcon
            icon={faChevronRight}
            className={[navButton, next].join(' ')}
          />
        )}
      />
    </div>
  )
}

export default Carrousel
